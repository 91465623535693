import React from 'react'
import {Form, Button} from 'react-bootstrap';

const SaveControl = ({entity,mode,valid}) => {

    return (
        <div>
        {
        mode>0 ?
            <Form.Group style={{justifyContent:"center", alignItems:"center", display: "flex", margin:"3rem 0 1rem 0"}}>
                <Button variant="success" type="submit" 
                style={ (entity.includes('Invalid') || !valid)
                    ? {width:"auto", fontSize:"1.2rem", backgroundColor:"transparent", color:"#ffc107", pointerEvents:'none', cursor:'not-allowed', fontWeight:'bold'} 
                    : {width:"auto", fontSize:"1.2rem"}}>
                <i className="fa fa-save"></i> { entity.includes('/') ? entity.slice(entity.indexOf('/')+1) : entity}
                </Button>
            </Form.Group>
        : null
        }
        </div>
    )
}

export default SaveControl
