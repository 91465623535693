import React, { useState } from "react"
import {Button,Form} from 'react-bootstrap'
import {ToNotify} from '../Shared/ViewControl'

const SignBasic = ({signApi}) => {
  const [message, setMessage] = useState('');
  const [starting, setStart] = useState('Start');
  const [valid, setValid] = useState(false);
  const [mode, setMode] = useState(signApi.mode)

  function onSubmit(event) {

      signApi.data.from= 3

      signApi.POST()
      .then(()=>{
        if(signApi?.mode>0) {
          //alert("signApi.message :"+signApi.message+" signApi.mode :"+signApi.mode)

          setMode(signApi.mode)
          setMessage(signApi.message)
        }
      })
  }

  const onChangeEmail = (e) => {

    if(signApi.isEmailValid(e.target.value)) {  
          setValid(true)
          setStart('Next')
    }
    else {
      setValid(false)
      setStart('Invalid Email')
    }  
  }

const onChangePassword = (e) => {

  if(signApi.isPasswordValid(e.target.value)) {
        setValid(true)
        setStart('Sign In')
  }
  else {
    setValid(false)
    setStart('Invalid Password')
  }  
}



const closeNotify = (e) => {
  setMessage('')

  if(mode===3) {
      window.location.reload()
  }
}    

  return (
    <div className="outlet">

<ToNotify 
message={message}
onClose={closeNotify}
/>    
      <h5 style={{color:'orange'}}>{message}</h5> 
      
      { mode<3 ?
      <Form style={{width:"100%"}}>
           
           <Form.Group style={{justifyContent:"center", display: "flex", alignItems:"center"}}>                        
               <Form.Control style={{display: "block", width:"70%", margin:"1rem"}}
               type="email"
               name="Email"
               required
               placeholder="Email"
               defaultValue=""
               onChange={onChangeEmail}
               />

              <Form.Control style={{display: "block", width:"70%", margin:"1rem"}}
              name="Password"
              type="password"
              required
              placeholder="Password"
              defaultValue=""
              onChange={onChangePassword}
              />
           </Form.Group>

           <Form.Group style={{justifyContent:"center", alignItems:"center", display: "flex", padding:"1rem"}}>
           <Button onClick={() => onSubmit()} size="lg" style={ valid ? {color:'white'} : {width:"33%",backgroundColor:'orange',color:'yellow', pointerEvents:'none', cursor:'not-allowed'}}>
                {starting}</Button>

           </Form.Group>
      </Form>
      : 
      null 
      }
    </div>

  )
}
export default SignBasic