import React,{Component} from 'react';
import { Form } from 'react-bootstrap';

export class CountryControl extends Component{
    constructor(props){
        super(props)
        this.state = {countries:[], loading:false, country:1}
        this.apiUrl=  this.props.apiUrl 

        this.itemId= 1
        this.label= 'Country'
        this.name= 'CountryId'
        this.readOnly = this.props.readOnly ?? false

        this.onChangeCountry = this.props.onChangeCountry
    }
 

    componentDidMount(){
        this.setState({loading:true})
        
        fetch(`${this.apiUrl}/setting/countries`)
        .then(response => response.json())
        .then(data => {
            this.setState({countries:data, loading:false})
        } )
        .catch(function (error) {
            console.log(error)
          })
    }

    componentWillUnmount() {
        this.setState({countries:null})        
    }

    onChange(event) {
        this.setState({country:event.target.value})
    }

    render() {
 
        return (

        <div>

            {this.state.loading ? 
                <h5>Countries are loading...</h5>             
            :
            <Form.Group className='form-row' style={{alignItems:'center', marginBottom:'6px'}}> 
                <Form.Label className='col-sm-3'>{this.label} :</Form.Label>
                <Form.Control className='col-sm-9' onChange={this.onChangeCountry} as="select" name={this.name} defaultValue={this.itemId} readOnly={this.readOnly} >
                    {this.state.countries.map(country => 
                        <option key={country.Id} value={country.Id}>{(country.Name+" "+country.Phone)}</option>
                    )}
                </Form.Control>
            </Form.Group>  
            }
</div> 
        
        )
 
    }
}

/*
style={{display: "block", width:"70%", margin:"1rem"}}
*/