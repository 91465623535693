import React from 'react'
import {Services} from './Service/Services'

const Home = ({search, Etat, connected=false}) => {

  return (
    <div className="mt-8 d-flex justify-content-center">

        { connected ?
        <>
            <Services Etat={Etat} search={search} />
      </>
          :
          <h5>RDS Sign ...</h5>
          }
    </div>
  )
}

export default Home
