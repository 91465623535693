import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-bootstrap'

const Footer = ({etat=''}) => {
    const [time, Time] = useState("00:00:00");

    useEffect(() => {
        setTimeout(() => {
            Time((new Date()).toLocaleTimeString())
        }, 1000);
    },[time]);

    return (
        <Row className="Footer">
            <Col sm={4}>
            <i className="FooterEtat" key="FooterEtat">{etat}</i>
            </Col>
            <Col  sm={1}>
            <a target="_blank" rel="noopener noreferrer" href={"https://telegram.me/raisdigitalbot"}><img src="images/s-telegram.png" alt="telegram" /></a>
            </Col>
            <Col  sm={1}>
            <a target="_blank" rel="noopener noreferrer" href={"https://www.linkedin.com/company/rais-digital-services"}><img src="images/linkedin.png" alt="linkedin" /></a>
            </Col>
            <Col  sm={1}>
            <a target="_blank" rel="noopener noreferrer" href={"https://twitter.com/RaisDigital"}><img src="images/twitter.png" alt="twitter" /></a>
            </Col>
            <Col  sm={1}>
            <a target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/RaisDigitalServices"}><img src="images/facebook.png" alt="facebook" /></a>
            </Col>
            <Col  sm={1}>
            <a target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/RaisDigitalServices"}><img src="images/instagram.png" alt="instagram" /></a>
            </Col>
            <Col  sm={3} style={{top:'20%'}}>
            <i><b>Rais Digital Services 2016 - </b>{time}</i>
            </Col>
        </Row>
    )
}

export default Footer;