import {Component} from 'react'
import Api from "../../Api"

export default class SignApi extends Component{

    constructor(props){
        super(props)
        this.state = {loading:false}
        this.data = {app:'RDS', email:'', code:'', phone:'', country:1, from:2, password:''}

        this.apiUrl=Api.defaults.baseURL

        this.headers = {            
            'Accept':'application/json',
            'Content-Type':'application/json'
        }
        this.mode=this.props.mode??1
        this.message=""
    }
    
    static mode
    static message

    isDataReady() {
        if(this.mode===1 && this.data.phone.length===10) {
            return true
        }
        else if(this.mode===2 && this.data.code.length===5) {
            return true
        }
        return false
    }

    isEmailValid(value) {
        this.message="Invalid Email?"
        if(value.length>4 && value.includes("@") && value.includes("."))
        {
            const emailRegex = /\S+@\S+\.\S+/;
            if (emailRegex.test(value)) {
                this.data.email=value
                this.message=""
                return true
            }
        }
        return false
    }

    isPasswordValid(value) {
        this.message="Invalid Password?"
        if(value.length>6)
        {
            const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
            if (passwordRegex.test(value)) {
                this.data.password=value
                this.message=""
                return true
            }
        }
        return false
    }

    async POST() {

        //console.log('data :', {...this.data, mode:this.mode})       
        //alert(`POST app:${this.data.app} from:${this.data.from} mode:${this.mode} phone:${this.data.phone} code:${this.data.code} country:${this.data.country}`)

        await fetch(`${this.apiUrl}/signin`,{
            method:'POST',
            headers:this.headers,
            body:JSON.stringify({...this.data, mode:this.mode})
        })
        .then(response => response.json())
        .then(result=> {                
                if(result.message) {
                    //alert('result : '+ result.message);  console.log('signin result',result)
                    if(result.message.includes("Salaam"))// && result.role)
                    {
                        if(this.mode===1) {
                            this.mode=2
                        }
                        else if(this.mode===2 || this.data.from===3) {
                            localStorage.setItem('RDS',JSON.stringify({
                                connected:true, ...result}   
                            ))
                            this.mode=3
                        }
                    }
                    this.message=result.message
                }
                else{
                    this.message="Error api: result message is null?"
                }

            },
            (error)=>{
                console.log('signin error :',error)
                //alert('signin error : '+ error.message)
                this.message=error.message
            }            
        )
        .catch(error=>{
            console.log('signin error :',error)
            this.message=error.message
        })
    }
}

