import React, { useState, useEffect } from "react"
import {Button,Form, Col} from 'react-bootstrap'
import {CountryControl} from '../Shared/CountryControl'
import {ToNotify} from '../Shared/ViewControl'

const SignPhone = ({signApi, onMode}) => {
  const [message, setMessage] = useState('')
  const [starting, setStart] = useState('Start')
  const [valid, setValid] = useState(false)
  const [mode, setMode] = useState(signApi.mode)

  useEffect(() => {
    if(valid) {
      if(signApi.isDataReady()) {
        onSubmit()
      }
    }
  }, [valid, signApi])  
  
  function onSubmit(event) {

      signApi.data.from= 2

      signApi.POST()
      .then(()=>{
        if(signApi?.mode>0) {

          setMode(signApi.mode)
          setMessage(signApi.message)
          onMode()
        }
      })
  }

  const onChange = (e) => {
    signApi.data.phone=e.target.value
    setValid(false)
    setStart('Invalid Phone')
    if(signApi.data.phone.length>9)
    {
        const phoneRegex = /^\d+$/;
        if (phoneRegex.test(signApi.data.phone)) {
          setValid(true)
          setStart('Next')
        }
    }
}

const onChangeCode = (e) => {
  signApi.data.code=e.target.value

  setValid(false)
  setStart('Invalid Code')
  if(signApi.data.code.length>4) {
        setValid(true)
        setStart('Sign In')
  }  
}

const onChangeCountry = (e) => {
  signApi.data.country=e.target?.value ?? 1
  setMessage(`Country ${signApi.data.country}`)
}

const closeNotify = (e) => {
  setMessage('')

  if(mode===3) {
      window.location.reload()
  }
}    

  return (

<div className="outlet">

<ToNotify 
message={message}
onClose={closeNotify}
/>    
      { mode<3 
      ?
      <Form style={{width:"100%"}}>
          
           
           <Form.Group style={{justifyContent:"center", display: "flex", alignItems:"center"}}>  
                      
           { mode===1 ?
           <Col sm={8} >

             <CountryControl onChangeCountry={onChangeCountry} apiUrl={signApi.apiUrl}/> 

               <Form.Control id="SignPhone" style={{display: "block", width:"100%", margin:"1rem"}}
               type="number"
               name="Phone"
               required
               placeholder="Phone number"
               defaultValue=""
               //defaultValue="660800630"
               onChange={onChange}
               />

              </Col>
              :
              <>
              <Form.Control id="SignCode" style={{display: "block", width:"50%"}}
              type="text"
              name="Code"
              required
              placeholder="TCode"
              defaultValue=""
              onChange={onChangeCode}
              />

              <a target="_blank" rel="noopener noreferrer" href="https://telegram.me/raisdigitalbot">
              <img className="Telegram" src="images/telegram.png" alt="@RaisDigitalBot" style={{marginLeft:"40%", width:"5rem"}} />
              </a>
              </>
           }
           </Form.Group>

           <Form.Group id="SignIn" style={{justifyContent:"center", alignItems:"center", display: "flex", padding:"1rem"}}>

           <Button onClick={() => onSubmit()} size="lg" style={ valid ? {color:'white'} : {width:"33%",backgroundColor:'darkgray',color:'gray', pointerEvents:'none', cursor:'not-allowed'}}>
                {starting}</Button>

           </Form.Group>
      </Form>
      : 
      null
      }

    </div>
  )
}
export default SignPhone

