import React, {useState} from "react"
import {Modal, Button, Row, Form, Collapse} from 'react-bootstrap'
import {CountryControl} from '../Shared/CountryControl'
import {ToNotify} from '../Shared/ViewControl'
import {TextControl} from '../Shared/InputControl'
import SaveControl from '../Shared/SaveControl'
import {RoleControl} from '../Shared/RoleControl'

const SignUp = ({app, show, onHide, api, Item=null}) => {
  const [message, setMessage] = useState('')
  const [code, setCode] = useState('') 
  const [starting, setStart] = useState('Invalid')
  const [valid, setValid] = useState(false)
  const [mode, setMode] = useState(Item?2:1)
  const [optional, Optional] = useState(false)
  const logo = 'images/logo.jpg'

    function onSubmit(event) {
      event.preventDefault()
      let item = {
        app,
        name:event.target.Name.value,
        role:event.target.RoleId.value,
        country:event.target.CountryId.value,
        phone:event.target.Phone.value,
        email:event.target.Email.value,
        password:event.target.Password.value,
        title:event.target.Title.value,
        infos:event.target.Infos.value
      }
      //console.log('SignUp item:',item)
      api.post('/signup', item)
      .then(response=>{
        console.log('SignUp response:', response.data)
        setMessage(response.data.message) 

        if(response.data.message.includes("Salaam"))
        {
          setCode(`: ${response.data.message}`)
          setMode(2)
        }
      })
      .catch(error => {        
        console.log('SignUp error:',error)
        setMessage(error.message)   
    })
  }

  const onPhone = (e) => {
    let phone=e.target.value
    setValid(false)
    setStart('Invalid Phone')
    if(phone.length>=9)
    {
        const phoneRegex = /^\d+$/;
        if (phoneRegex.test(phone)) {
          setValid(true)
          setStart('Account')
        }
    }
}

const onEmail = (e) => {
  let email=e.target.value

  setValid(false)
  setStart('Invalid Email')
  if(email.length>4 && email.includes("@") && email.includes("."))
  {
      const emailRegex = /\S+@\S+\.\S+/;
      if (emailRegex.test(email)) {
        setValid(true)
        setStart('Account')
      }
  }
}


  return (
    <div>

<ToNotify 
message={message}
onClose={()=>{setMessage('')}}
/>   

    <Modal show={show}
            onHide={onHide}
                size="lg"
                animation={true}
                centered
            >
    
                <Modal.Header closeButton>
                    <Row style={{ justifyContent:"center", width:"100%"}}>
                        <img  src={logo} alt="Home" height="160px" />
                    </Row>    
                </Modal.Header>
    
                <Modal.Body style={{border:"5px", borderRadius:"15px", borderColor:"darkblue"}}>
    
                    <h5 style={{color:'orange'}}>{message}</h5> 

<Form onSubmit={onSubmit} style={{justifyContent:"center", alignItems:"center", width:"85%", margin:"0 7% 3% 7%"}}>
<div className="degrade-diagonal" style={{border:"12px solid #33498A", borderRadius:"15px", padding:"0rem 3rem 1rem 3rem", justifyContent:"center"}}>
    <h3 style={{color:"darkblue", margin:"2rem"}}>Sign Up {app} {code}</h3>
<TextControl name="Name" value={Item ? Item.Name:''} mode={mode} required />
<TextControl name="Phone" value={Item ? Item.Email:''} onChange={onPhone} mode={mode} type="number" required/>
<CountryControl apiUrl={api.defaults.baseURL} /> 

<Button onClick={()=>{Optional(!optional)}} 
  style={{ backgroundColor:"transparent" ,borderColor:"transparent", width:'100%', height:'3rem', margin:'1rem'}}>
  <h3 style={{color:"darkgray"}}>Optional infos</h3>
</Button>   
  <Collapse in={optional}>
    <div style={{width:'100%'}}>
      <TextControl name="Email" value={Item ? Item.Email:''} onChange={onEmail} mode={mode} type="email" />
      <TextControl name="Password" value={Item ? Item.Email:''} mode={mode} type="password" />
      <TextControl name="Title" value={Item ? Item.Title:''} mode={mode} />
      <TextControl name="Infos" value={Item ? Item.Infos:''} mode={mode} />
      <RoleControl apiUrl={api.defaults.baseURL} /> 
    </div>
  </Collapse>
  <SaveControl mode={mode} entity={starting} valid={valid} />

</div>
    
</Form>

    </Modal.Body>

  <Modal.Footer>
    <Button variant="outline-danger" onClick={onHide}><i className="fa fa-close"></i>  Close</Button>
  </Modal.Footer>
</Modal>

            </div>

  )
}
export default SignUp