import React,{Component} from 'react';
import { Form } from 'react-bootstrap';

export class RoleControl extends Component{
    constructor(props){
        super(props)
        this.state = {items:[], loading:false, role:1}
        this.apiUrl=  this.props.apiUrl ?? 'https://api.errais.net'

        this.itemId= 1
        this.label= 'Role'
        this.name='RoleId'
        this.readOnly = this.props.readOnly ?? false
    }
 

    componentDidMount(){
        this.setState({loading:true})
        
        fetch(`${this.apiUrl}/setting/roles`)
        .then(response => response.json())
        .then(data => {
            this.setState({items:data, loading:false})
        } )
        .catch(function (error) {
            console.log(error)
          })
    }

    componentWillUnmount() {
        this.setState({items:null})        
    }

    onChange(event) {
        if(event?.target)
            this.setState({role:event.target.value})
    }

    render() {
 
        return (
    <>
            {this.state.loading ? 
                <h5>Roles are loading...</h5>             
            :
            <Form.Group className='form-row' style={{alignItems:'center', marginBottom:'6px'}}>
                <Form.Label className='col-sm-3'>{this.label} :</Form.Label>
                <Form.Control className='col-sm-9' id={this.name}  as="select" name={this.name} defaultValue={this.itemId} readOnly={this.readOnly} >
                    {this.state.items.map(item => 
                        <option key={item.Id} value={item.Id}>{(item.Name)}</option>
                    )}
                </Form.Control>
            </Form.Group>  
            }
    </>          
        )
    }
}