import React, {useState, useRef} from 'react'
import {Form, FormControl} from 'react-bootstrap'

const SearchControl = ({onSearch}) => {
    //const searched = useRef("") 
    const [showing, Showing] = useState(false)
    const search = useRef("")

    const onChange = (e) => {
        onSearch(e.target.value)
        search.current=e.target.value
    }

    const onClick = (e) => {
        if(showing) {
            onSearch(search.current)
        }
        Showing(!showing)
    }

    return (
        <>
                        
        <i className="fa fa-search" style={{ fontSize:'1.1rem', marginLeft:"1rem"}} onClick={onClick} ></i>

        { showing ?       
            <FormControl 
                className="d-inline" 
                style={{ textAlign: 'left', backgroundColor:'lightblue', width:"5rem", fontSize:'1.2rem' }} 
                name="Search" type="text" 
                defaultValue=""
                onChange={onChange}
                placeholder="" />
        : null }

        </>
    )
}


const TextControl = ({name,value,mode,type,onChange}) => {

    return (
        <Form.Group controlId={name} className='form-row' style={{marginBottom:'6px'}}>
                <Form.Label className='col-sm-3' >{name} :</Form.Label>
                <Form.Control
                className='col-sm-9'
                type={type ?? 'text'}
                name={name}                
                placeholder={name}
                fontSize='1.5rem'
                //value={value}
                defaultValue={mode===2?"":value}                
                readOnly={mode===0}
                onChange={onChange}
                //  required
                />
            </Form.Group>        
    )
}

//export default InputControl
export {SearchControl, TextControl}
