import React from 'react'
import './Service.css'
import styled from 'styled-components'

const Services = ({api, Etat, search}) => {

    return (
        <div className='Services' >

<Service name="DigitalOffice" 
  icon="/assets/logo.png" 
  info={"DigitalOffice"} 
  plan="Basic"
  start={"DigitalOffice"}
  to={"http://digitaloffice.ma"}
/>

<Service name="Synchronize" 
  icon="https://digitalservices.ma/logo.png" 
  info={"Synchronize"} 
  plan="PRO"
  start={"Synchronize"}
  to={"https://sync.2us.ma"}
  Etat={Etat}
/>

<Service name="Tech 2 US" 
  icon="https://tech2us.ma/images/slogo.png" 
  info={"Tech2us"} 
  plan="Basic"
  start={"Tech2us"}
  to={"https://tech2us.ma"}
  Etat={Etat}
/>

</div>
    )

}

const Service = ({name, icon, info, plan, start, to, style, Etat}) => {

    //alert(info)
    let image = icon.startsWith("http") ? icon : to+icon

    return (
        <div className='Service' style={style}>
            <div className="center">
                <a target="_blank" rel="noopener noreferrer" href={to}>
                    <img src={image} alt={name+": "+info} />
                </a>
            </div>
            <br/>
            <div className="ServiceName">
                <h2 style={{fontWeight: 'bold'}}>{name}</h2>
                <h6 className="ServiceInfo">{info}</h6>
                <br/>
                <h4 className="ServiceInfo">{plan}</h4>
                <br/>
            </div>
        </div>
    )
}

const Button = styled.button`
    border: 1px solid #616A94;
    border-radius: 50px;
    padding: 15px 30px;
    margin: 10px;
    text-decoration: none ;
    text-align: center;
    color: white;
    background-color: darkgreen;
    transition: 0.3s;
    font-size: 1em;
    cursor: pointer;
    outline: none;
    
    &:hover {
        color: white;
        font-weight: bold;
        background-color: green;
        border : 6px solid silver ;
    }
`;

export {Service, Services}

/*
<button title="button title" class="action primary tocart" onclick=" window.open('https://digitalservices.ma', '_blank'); return false;">
Akademy
</button>
by the use of return false we can remove/cancel the default behavior of the button like submit.
*/

/**
 *                     <Button onClick={() => { 
                        window.open({to}, '_blank')
                        return false
                    }}>{start}</Button>

 */