import React, {useState, useCallback, useRef, useEffect} from 'react'
import './App.css';
import Home from './components/Home'
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  const route = useRef("/Sign")

  const [connected, Connection] = useState(false)
  const [app, setApp] = useState("")
  const [search, Search]  = useState("")
  const [etat, Etat]  = useState("")
  const [layout, Layout] = useState(window.matchMedia('(orientation:landscape)').matches)

  const onResize = useCallback(e => {
    if(route.current!=="/") {
      let value=window.matchMedia('(orientation:landscape)').matches    
      Layout(value)
    }
  }, [])

  useEffect(() => {
          window.addEventListener("resize", onResize);
          return () => {
              window.removeEventListener("resize", onResize);
          };
  }, [layout])  


  return (
    <div className='App'>

    <BrowserRouter>

    <Header appConnection={Connection} appNamp={setApp} Search={Search} />


      <Routes>    
        <Route path="/" element={<Home search={search} Etat={Etat} connected={connected} />} />
        
        <Route path='/services' component={() => { 
          window.location.href = 'https://digitalservices.ma/'
          return null
        }} />
        <Route path='/office' component={() => { 
          window.location.href = 'https://digitaloffice.ma/'
          return null
        }} />
      </Routes>

      
    <Footer/>

    </BrowserRouter>

    </div>


  )
}

export default App;
