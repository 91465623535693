import React, {useState, useEffect} from 'react'
import {Row, Col, Navbar} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import {Navigation} from './Navigation'
import SignModal from './Sign/SignModal'
import {useLocation} from 'react-router-dom'
import SignUp from './Sign/SignUp'
import {SearchControl} from './Shared/InputControl'
import Api from '../Api'

const Header = ({appConnection, appNamp, Search}) => {

    const [showin, setShowin] = useState(false)
    const [register, Register] = useState(false)
    const [storage, setStorage] = useState(null)
    const [connected, setConnection] = useState(false)
    const logo = 'images/logo.jpg'

    const { search } = useLocation()
    const [app, setApp] = useState("Services")

    const Logout = (e) => {
        //alert('remove token')
        localStorage.removeItem('RDS')
        window.location.reload()
    }

    const Login = (e) => {
        setShowin(true)
    }

    const LinkPhone = (e) => {
            
        Api.post('/setting/linking')
        .then(data => {
            //alert(data.message)
            //console.log('data',response.data)
    
        })
        .catch(error => console.error({ error }))
    
    }
    
    useEffect(() => {
//        alert('search...')
//        console.log('search',search)
        if(search) {
            let params = new URLSearchParams(search)
//            console.log('params',params)
            if(params) {
                setApp(params.get("app"))
//              console.log('app',app)
            }
        }
        appNamp(app)
    }, [search,app])

    useEffect(() => {
        //setShowup(true)
        setShowin(true)
        const ls = JSON.parse(localStorage.getItem('RDS'))
        if(ls)
        {
            setStorage(ls)
            setConnection(true)
            appConnection(connected)
            //console.log('appConnection', connected)
            setShowin(false)
        }

    }, [connected, appConnection])    

    //  console.log('Header.storage',storage, connected)
    return (
        
        <div className="Header">
            <Row className="navbar navbar-custom" expand="lg" style={{justifyContent:"center", display: "flex"}}>
                <Col sm={1}>
                    <NavLink to="/" >
                        <img className="Logo" src={logo} alt="Home" height="90px" padding="5px"  />
                    </NavLink>
                </Col>
                <Col  sm={6}>
                    <Navigation connected={connected}/>
                </Col>
                <Col  sm={2}>
                    <SearchControl onSearch={Search} />
                </Col>
                <Col  sm={1}>
                { storage?.connected ? 
                <Navbar>
                    <NavLink className="d-inline p-3 navbar-custom" to="/" style={{float:'right'}} onClick={LinkPhone}><i className="fa fa-link" alt="Link a phone"></i></NavLink>
                    <NavLink className="d-inline p-3 navbar-custom" to="/" style={{float:'right'}} onClick={Logout}><i className="fa fa-sign-out" alt="Log out"></i></NavLink>
                </Navbar>
                :
                <Navbar>
                    <NavLink className="d-inline p-3 navbar-custom" to="/" style={{float:'right'}} onClick={Login}><i className="fa fa-sign-in"></i></NavLink>  
                    <NavLink className="d-inline p-3 navbar-custom" to="/" style={{float:'right'}} onClick={()=>{Register(!register)}}><i className="fa fa-edit"></i></NavLink>  
                </Navbar>
                }
                </Col>
            </Row>

<SignModal 
    app={app}
    show={showin}
    onHide={()=>{setShowin(false)}}
/> 

<SignUp 
    api={Api}
    app={app}
    show={register}
    onHide={()=>{Register(false)}}
/>   
        </div>
        
    )
}

export default Header;