import React, { useState } from "react"
import {Button,Form} from 'react-bootstrap'
import {ToNotify} from '../Shared/ViewControl'

const SignEmail = ({signApi, onMode}) => {

  const [message, setMessage] = useState('');
  const [starting, setStart] = useState('Start');
  const [valid, setValid] = useState(false);
  const [mode, setMode] = useState(signApi.mode)

  function onSubmit(event) {

      signApi.data.from= 1

      signApi.POST()
      .then(()=>{
        if(signApi?.mode>0) {
          //alert("signApi.message :"+signApi.message+" signApi.mode :"+signApi.mode)

          setMode(signApi.mode)
          setMessage(signApi.message)
          onMode()
        }
      })
  }


  const onChange = (e) => {
    signApi.data.email=e.target.value

    setValid(false)
    setStart('Invalid Email')
    if(signApi.data.email.length>4 && signApi.data.email.includes("@") && signApi.data.email.includes("."))
    {
        const emailRegex = /\S+@\S+\.\S+/;
        if (emailRegex.test(signApi.data.email)) {
          setValid(true)
          setStart('Next')
        }
    }
}

const onChangeCode = (e) => {
  signApi.data.code=e.target.value

  setValid(false)
  setStart('Invalid Code')
  if(signApi.data.code.length>4) {
        setValid(true)
        setStart('Sign In')
  }  
}



const closeNotify = (e) => {
  setMessage('')

  if(mode===3) {
      window.location.reload()
  }
}    

  return (
    <div className="outlet">

<ToNotify 
message={message}
onClose={closeNotify}
/>    
      
      { mode<3 ?
      <Form style={{width:"100%"}}>
           
           <Form.Group style={{justifyContent:"center", display: "flex", alignItems:"center"}}>  
                      
           { mode===1 ?
               <Form.Control style={{display: "block", width:"70%", margin:"1rem"}}
               type="email"
               name="Email"
               required
               placeholder="Email"
               defaultValue="" //"services@raisdigital.ma"
               onChange={onChange}
               />
              :
              <Form.Control style={{display: "block", width:"70%", margin:"1rem"}}
              name="Code"
              required
              placeholder="Code5"
              defaultValue=""
              onChange={onChangeCode}
              />
           }
           </Form.Group>

           <Form.Group style={{justifyContent:"center", alignItems:"center", display: "flex", padding:"1rem"}}>

           <Button onClick={() => onSubmit()} size="lg" style={ valid ? {color:'white'} : {width:"33%",backgroundColor:'orange',color:'yellow', pointerEvents:'none', cursor:'not-allowed'}}>
                {starting}</Button>

           </Form.Group>
      </Form>
      : 
      null
      }
    </div>

  )
}
export default SignEmail