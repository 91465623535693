import React, {useState, useEffect} from "react"
import {Modal,Button,Row} from 'react-bootstrap'
import SignEmail from "./SignEmail"
import SignPhone from "./SignPhone"
import SignBasic from "./SignBasic";
import SignApi from './SignApi'

const signApi = new SignApi({mode:1})


const SignModal = ({app, show, onHide}) => {

  const [activeTab, setActiveTab] = useState("Phone")
  const [mode, setMode] = useState(signApi.mode)
  const [code, setCode] = useState('')
  const logo = 'images/logo.jpg'

    const onEmail = () => {
        setActiveTab("Email")
    }

    const onPhone = () => {
        setActiveTab("Phone")
    }

    const onBasic = () => {
      setActiveTab("Basic")
    }

    useEffect(() => {
      setCode(signApi.message)
      signApi.data.app=app
      //alert(signApi.data.app)
  }, [mode, app]);

  const onMode=()=>{
    setMode(signApi.mode)
  }

  return (
    <div className="mt-5 d-flex justify-content-left">

    <Modal show={show}
            onHide={onHide}
                size="lg"
                animation={false}
                centered
            >
    
                <Modal.Header closeButton>
                    <Row style={{ justifyContent:"center", width:"100%"}}>
                        <img  src={logo} alt="Home" height="160px" />
                    </Row>
    
                </Modal.Header>
    
                <Modal.Body>
                    <Row  style={{ justifyContent:"center", width:"100%"}}>
                        <h3 style={{color:"darkblue", margin:"0rem"}}>Sign In {app} </h3>
                    </Row>          
    
    <div className="Tabs">
      { mode===1 ?
      <ul className="nav">
        <li className={activeTab === "Phone" ? "active" : ""} onClick={onPhone}>Phone</li>
        <li className={activeTab === "Email" ? "active" : ""} onClick={onEmail}>Email</li>
        <li className={activeTab === "Basic" ? "active" : ""} onClick={onBasic}>Basic</li>
      </ul>
      : 
      <h3 style={{color:"lightblue", margin:"2rem"}}>{code}</h3> 
      }
      
          {activeTab === "Phone"      ? <SignPhone signApi={signApi} onMode={onMode} /> 
            : ( activeTab === "Email" ? <SignEmail signApi={signApi} onMode={onMode} /> 
                                      : <SignBasic signApi={signApi} /> ) }
    </div>

    </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-danger" onClick={onHide}><i className="fa fa-close"></i> Close</Button>
            </Modal.Footer>
          </Modal>
 
            </div>

  )
}
export default SignModal